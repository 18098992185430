@import "./base.scss";

section#services {
    background-color: $orange-1;
    z-index: 5;
    max-width: 80%;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: -30px;
    div#servicesList {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        width: 100%; 
        align-content: space-between;
        justify-items: center;
        align-items: center;
        a {
            position: relative;
            display: block;
            align-content: space-between;
            align-items: stretch;
            max-width: 50%;
            flex-basis: 20%;
            margin-left: auto;
            margin-right: auto;
            z-index: 6;
            flex-grow: 4;
            @media screen and (max-width: 800px) {
                font-size: 1.4em;
                flex-basis: 50%;
                min-width: 100%;
            }
            .serviceItem {
                transform: translateY(-80px);
                display: flex;
                flex-direction: column;
                cursor: pointer;
                height: 200px;
                background-color: $orange-3;
                margin: 5px;   
                color: $blue-4;
                transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out , opacity 0.5s ease-in-out;
                flex-basis: 25%;
                z-index: 5;
                .decor {z-index: 20;}
                &:hover {
                    background-color: $orange-4;
                    color: $orange-1;
                    transform: scale(1.05) translateY(-80px);
                    z-index: 20;
                    >div.serviceIcon {
                        display: flex;
                        opacity: 1;
                        transform: scale(1) translateY(0);
                    }
                    h5 {
                        transform: translateY(0);
                    }
                    p {
                        color: $blue-4;
                        transform: translateY(0);
                    }
                    .decor {
                        position: absolute;
                        transform: translate(50%, 50%);
                        visibility: visible;
                        opacity: 1;
                        z-index: -20;
                    }
                }
                >div.serviceIcon {
                    display: flex;
                    opacity : 0 ;
                    position: relative;
                    transform: scale(0.5) translateY(-100px);
                    transition-property: scale, transform, opacity;
                    transition-duration: 0.5s, 0.2s, 0.4s;
                    transition-timing-function: ease-in-out;
                    width: 35px;
                    height: 20%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 15px;
                    margin-bottom: 0px;
                    .st0{fill:$orange-5;}
                }
                h5 {
                    display: block;
                    position: relative;
                    font-family: 'Bitter', serif;
                    font-style: italic;
                    height: 20%;
                    font-size: 0.9em;
                    font-weight: 400;
                    margin: 0 15px;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    bottom: 0;
                    transform: translateY(-30px);
                    transition: transform 0.5s ease-in-out;
                }
                p {
                    font-size: 0.7em;
                    color: rgb(100, 100, 100);
                    margin: 8px 12px;
                    text-align: center;
                    margin-bottom: 15px;
                    transform: translateY(-30px);
                    transition: transform 0.5s ease-in-out;
                }
                .decor {
                    position: absolute;
                    display: block;
                    height: 40px;
                    width: 40px;
                    bottom: 0%;
                    right: 50%;
                    margin: 0;
                    padding: 0;
                    visibility: hidden;
                    opacity: 0;
                    transform: translate(50%, 100%) rotateX(120deg);
                    transition: transform 0.6s ease-in-out, opacity 0.5s ease-in-out;
                    z-index: 20;
                }
            }
        }
    }
}