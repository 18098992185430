@import "./base.scss";

section#contactForm {
    display: block;
    position: relative;
    width: 100%;
    background-color: rgb(226, 226, 226);
    padding-top: 5px;
    padding-bottom: 10px;
    >#content {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        h2 {
            text-align: left;
            font-family: 'Bitter', serif;
            color: $blue-4;
        }
        form { 
            width: 100%;
            >#bothColumns {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                box-sizing: border-box;
                input {
                    font-size: 0.7em;
                    padding: 7px;
                    margin-bottom: 10px;
                    border: 1px solid $blue-3;
                    &::placeholder {
                        color:rgb(150, 150, 150);
                    }
                }
                >#column1 {
                    position: relative;
                    width: 36%;
                    display: flex;
                    flex-direction: column;
                    margin: auto;
                    margin-left: 0;
                    @media screen and  (max-width: $colapse) {
                        width: 100%;                    
                    }
                }
                >#column2 {
                    display: flex;
                    flex-direction: column;
                    margin: auto;
                    margin-right: 0;
                    width: 61%;
                    height: 100%;
                    @media screen and  (max-width: $colapse) {
                        width: 100%;                    
                    }
                    textarea {
                        font-family: 'Nunito Sans', sans-serif;
                        border: 1px solid $blue-3;
                        min-height: 100px;
                    }
                    >#footerSender {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        >div#privPoli {
                            width: 70%;
                            font-size: 0.6em;
                            display: flex;
                            flex-direction: row;
                            >p > a {
                                color: $orange-5;
                                text-decoration: none;
                                padding-left: 3px;
                                &:hover {
                                    text-decoration: underline;
                                }
                            
                            }
                        }
                        >button {
                            cursor: pointer;
                            font-family: 'Bitter', serif;
                            font-size: 0.8em;
                            padding: 7px 40px;
                            margin-left: 20px;
                            color: $orange-1;
                            border: 1px solid rgb(226, 226, 226);
                            outline: 1px solid $orange-4;
                            background-color: $orange-4;
                            z-index: 1;
                            &:hover {
                            color: $orange-1;
                            background-color: $orange-5;
                            }
                        }
                    }
                }
            }
        }   
    }
}
