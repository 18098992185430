@import "./base.scss";

section#news {
    width: 100%;
    >div {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        >h2 { 
            text-align: left;
            font-family: 'Bitter', serif;
            color: $blue-4;
        }
        >article {
            display: flex;
            flex-direction: column;
            >div {
            display: flex;
            justify-content: space-between;
                >.column {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    height: 150px;
                    width: 100%;
                    >div.arPhoto {
                        display: flex;
                        justify-content: center;
                        position: relative;
                        overflow: hidden;
                        margin-right: 10px;
                        >img {
                            height: 100%;
                        }
                    }
                    >div.arContent {            
                        text-align: left;
                        margin-bottom: 20px;
                        >h4 {
                            margin: 0;
                            white-space: nowrap;
                            @media screen and (max-width: $colapse) {
                                margin-top: 20px;
                            }
                        }
                        >p{
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 7;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 0.8em;
                            color: rgb(50, 50, 50);
                        }
                    }
                }
            }
            >div.arFoot {
                display: flex;
                width: 100%;
                justify-content: right;
                margin-top: 10px;
                >div {
                    display: flex;
                    position: relative;
                    justify-content: space-between;
                    align-content: flex-end;
                    margin-bottom: 30px;
                    width: 50%;
                    @media screen and (max-width: $colapse) {
                        width: 100%;
                    }
                    flex-direction: row;
                    border-bottom: solid 1px rgb(120, 120, 120);
                    font-size: 0.7em;
                    bottom: 0;
                    padding-bottom: 15px;
                    >div.arDate {
                        color: rgb(120, 120, 120);
                    }
                    >div.arLink {
                        color:  $orange-5;
                            &:hover {
                                font-weight: 700;
                            }
                    }
                }
            }
        }
    }
}