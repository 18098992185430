@import "./base.scss";

section#companyDescription {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 4;
    p {
        font-size: 0.8em;
        text-align: left;
    }
    >h2 {
        width: 80%;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        color: $blue-4;
        text-align: left;
        font-family: 'Bitter', serif;
        font-weight: 400;
    }
    >#gOpis {
        @include flexCenter;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        >.sec {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 10px;
        }
        >div>div {
            width: 48%;
            color: rgb(100, 100, 100);
            @media screen and (max-width: $colapse) {
                width: 100% !important;
            }
        }
        >#opis1 {
            display: flex;
            flex-direction: row;
            @include colapseRow;
            >div {
                display: flex;
                align-items: center;
                width: 49%;
                >p {
                    margin-bottom: 0;
                }
            }
        }
        >#opis2 {
            position: relative;
            align-items: center;
            display: flex;
            flex-direction: row;
            @include colapseRow;
            #contactLink {
                position: absolute;
                left: 52%;
                width: 61%;
                height: 100%;
                @include flexCenter;
                background-color: $orange-4;
                border: 3px solid $orange-1;
                outline: 1px solid $orange-4;
                @media screen and (max-width: $colapse) {
                    position: relative;
                    width: 100%;
                    left: 0;
                    margin-top: 15px;
                }
                >.contactBacground {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    .st0, .st1 {
                        fill: $orange-3;
                        opacity: 0.3;
                    }
                    #Layer_1 {
                        position: absolute;
                        display: block;
                        height: 100%;
                    }
                }
                >.contactIcon {
                    margin: 10px;
                    width: 50px;
                    margin-left: auto;
                    margin-right: auto;
                    z-index: 1;
                }
                >button {
                    cursor: pointer;
                    font-family: 'Bitter', serif;
                    font-size: 0.8em;
                    padding: 5px 22px;
                    color: $blue-4;
                    border: 2px solid $orange-4;
                    outline: 0.5px solid $orange-1;
                    background-color: $orange-1;
                    z-index: 1;
                    &:hover {
                        color: $orange-1;
                        background-color: $orange-5;
                    }
                }
                >p {
                    width: 65%;
                    text-align: center;
                    color: $blue-4;
                    font-size: 0.7em;
                    z-index: 1;
                }
            }
        }
    }
}