@import "base.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#top-scroller {
  position: fixed;
  bottom: 35px;
  right: 35px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  z-index: 999;
  button {
    width: 35px;
    height: 35px;
    background-color: $grey-5;
    transition: transform 0.1s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}

body, html, main {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 360px !important;
}

header {
  width: 100%;
  display: flex;
 // align-content: center;
  //align-items: center;
  justify-content: center;
  min-width: 360px !important;
}