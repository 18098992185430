@import "../base.scss";

button.universalBtn {
    display: inline-block;
    position: relative;
    background-color: $blue-35;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 40px;
    height: 40px;
    >div {
        width: 100%;
        height: 100%;
        border: none;
        padding: 0;

        .st1 {
            fill:none;
            stroke:#FFFFFF;
            stroke-width:2;
            stroke-linecap:round;
            stroke-linejoin:round;
            stroke-miterlimit:10;
        }
    }
    &:hover {
        background-color: $orange-2;
        .st1 {
            fill:none;
            stroke: $orange-4;
            stroke-width:2;
            stroke-linecap:round;
            stroke-linejoin:round;
            stroke-miterlimit:10;
        }
    }
}