@import "./base.scss";

section#massGallery {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    flex-wrap: wrap;
    >div {
        width: 80%;
        position: relative;
        flex-direction: row;
        justify-content: space-around;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        >img {
            position: relative;
            display: block;
            max-width: 30%;
            max-height: 120px;
            margin: 10px;
            transition: transform 0.2s ease-in-out;
            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
        }
    }
    button {
        position: sticky;
        display: block;
        right: 0;   
        top: 10px;
        height: 30px;
        z-index: 10;
        background-color: $blue-3;
        color: $orange-1;
        font-family: 'Nunito Sans', sans-serif;
        box-shadow: none;
        border: none;
        outline: 4px white solid;
        color: $orange-1;
        &:hover {
            background-color: $orange-2;
            color: $blue-4;
            font-weight: 700;
        }
    }
}
