@import "./base.scss";

section#map { 
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    height: 230px;
    iframe#gMap {
        width: 100%;
        height: 230px;
        border: none;
        margin: 0;
        padding: 0;
    }
}
