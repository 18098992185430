@import "./base.scss";

section#footer {
    color: $orange-1;
    width: 100%;
    background-color: $blue-4;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-top: 0;
    @media screen and (max-width: $colapse) {
        padding-bottom: 10px;
       
    }
    div#content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-bottom: 2px solid $blue-3;
        @media screen and (max-width: $colapse) {
            border-bottom: none;
        }
       div#left {
            width: 50%;
            order: 0;
            @media screen and (max-width: $colapse) {
                order: 1;
                width: 100%;
                margin-top: 10px;
            }
            >h2 {
                text-align: left;
                font-family: 'Bitter', serif;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                display: block;
                @media screen and (max-width: $colapse) {
                    display: none;
                }
            }
            div#navs {
                display: flex;
                flex-direction: row;
                @media screen and (max-width: $colapse) {
                    flex-direction: column;
                }
                nav {
                    display: flex;
                    flex-direction: row;
                    width: 50%;
                    @media screen and (max-width: $colapse) {
                        width: 100%;
                    }
                    &:first-child {
                        @media screen and (max-width: $colapse) {  
                            border-bottom: 2px solid $blue-3;
                        }
                    }
                    >ul {
                        display: flex;
                        padding: 0;
                        flex-direction: column;
                        text-align: left;
                        list-style-type: none;
                        color: $grey-3;
                        @media screen and (max-width: $colapse) {
                            flex-direction: row;
                            margin: 2px;
                            width: 100%;
                            justify-content: center;
                            flex-wrap: wrap;
                        }
                            li {
                                cursor: pointer;
                                margin: 6px;
                                font-size: 0.7em;
                                padding-left: 4px;
                                &:hover {
                                    color: white;
                                    &::marker {
                                        content: ">";
                                        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                                        color: $orange-54;
                                        
                                    }
                                }
                                
                            }
                        }

                    }
            }
       }   
        #contactData {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            order: 1;
            width: 40%;
            text-align: left;
            font-size: 0.7em;
            @media screen and (max-width: $colapse) {
                order: 0;
                width: 100%;
                flex-direction: row;
            }
            div#contactInfo{
                >h2 {
                    font-size: 2.2em;
                    text-align: left;
                    font-family: 'Bitter', serif;
                    display: none;
                    @media screen and (max-width: $colapse) {
                        display: block;
                    }
                } 
                #telephone {
                    white-space: nowrap;
                    color: $orange-4;
                    font-size: 2em;
                }
                #address {
                    line-height: 2em;
                }
            }    
            #socialMedia {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: baseline;
                align-content: baseline;
                margin-top: 30px;
                div {
                    display: flex;
                    bottom: 0;
                    ul {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        padding: 0;
                        width: 100%;
                        list-style: none;
                        li {
                            padding: 5px;
                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }   
}   