@import "./base.scss";

section#haGallery {
    @include flexCenter;
    position: relative;
    width: 100%;
    height: 100%;
    height: 250px;
    @include colapseRow;
        >div {
            @include flexCenter;
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            >div {
                display: block;
                position: absolute;
                width: 80%;
                height: 80%;
                object-fit: cover;
                object-position: center;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
            >div#ph1 {
                transform: translate( -20%, -10%);
                z-index: 1;
                background-image: url("./photos/Main_Slider_02.jpg");
            }
            >div#ph2 {
                z-index: 2;
                background-image: url("./photos/Main_Slider_01.jpg");
            }
            >div#ph3 {
                transform: translate( 20%, 10%);
                z-index: 1;
               background-image: url("./photos/Main_Slider_03.jpg");
            }
        }
}