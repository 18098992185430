@import "./base.scss";

section#sliderWindow {
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $orange-1;
    z-index: 100;
    >h2 {
      width: 100%;
      text-align: left;
      font-family: 'Bitter', serif;
      color: $blue-4;
    }
    >button {
      display: block;
      position: relative;
      width: 40px;
      height: 40px;
      background-color: transparent;
      margin: auto;
      margin-bottom: 5px;
      border: none;
      >svg {
        position: relative;
        width: 40px;
        height: 40px;
        display: block;
        fill: $blue-35;
        padding: 0;
        margin: 0;
        &:hover {
          fill: $orange-2;
         .x {
            stroke: $orange-4;
          }
        }
      }
    }
    h4 {
      font-family: 'Bitter', serif;
      font-style: italic;
      font-weight: 400;
    }
    .cont {
      display: block;
      position: relative;
      width: 100%;
      margin: auto;
      background-color: $orange-15;
      img {
          position: relative;
          margin: auto;
          display: block;
          max-width: 1200px;
          max-height: 60vh;;
          z-index: 100;
      }
  }
  .custom-prev-arrow,
  .custom-next-arrow {
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .custom-prev-arrow {
    left: 10px;
    transform: translateY(-50%);
  }
  .custom-next-arrow {
    right: 10px;
    transform: translateY(-50%);
  }
}