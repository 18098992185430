@import "./base.scss";

section#serDetails {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 35px;
    div#content {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        h2 {
            text-align: left;
            font-family: 'Bitter', serif;
            color: $blue-4;
        }
        article.innerArticle {
            display: flex;
            position: relative;
            flex-direction: column;
            border-bottom: 1.5px solid grey;
            text-align: left;
            >div#texts {
                display: flex;
                position: relative;
                flex-direction: row;
                div.iconColumn {
                    svg {
                        margin: 15px;
                        height: 40px;
                        width: 40px;
                    }
                }
                div.textColumn {
                    h4 {
                        text-align: left;
                        font-family: 'Bitter', serif;
                        font-weight: 400;
                        font-style: italic;
                        color: $blue-4;
                        margin-bottom: 0;
                    }
                    p {
                        margin-top: 0;
                        font-size: 0.75em;
                        margin-bottom: 22px;
                    }
                    ul {
                        font-size: 0.75em;
                        line-height: 1;
                        margin-bottom: 25px;
                        li {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
            >div#hashes {
                display: flex;
                position: relative;
                flex-wrap: wrap;
                flex-direction: row;
                width: 100%;
                font-size: 0.6em;
                color: $grey-4;
                align-items: center;
                align-content: center;
                justify-content: center;
                text-align: center;
                text-justify: auto;
                >span {
                    display: flex;
                    position: relative;
                };
            }
        }
    }
}