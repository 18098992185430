@import "./base.scss";

section#ourProjects {
    margin:auto;
    width: 80%;
    position: relative;
    display: block;
    background-color: $orange-1;
    margin-bottom: 30px;
    >h2 {
      width: 80%;
      text-align: left;
      font-family: 'Bitter', serif;
      color: $blue-4;
    }
    h4 {
      font-family: 'Bitter', serif;
      font-style: italic;
      font-weight: 400;
    }
    .tint {
      position: relative;
      img {
        position: relative;
      //  width: 200px;
        height: 140px;  
        background-color: green;
        margin: auto;
        z-index: 10;
      }
      .overlay {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
      }
    }
    .my-div.selected {
      .tint {
        .overlay {
          background-color: rgba($blue-4, 0.5);
        }
      }
      h4 {
        color: $orange-4;
        font-weight: 700;
      }
    }
    .slick-slide {
      &:hover {
        h4 {
          font-weight: 700;
        }
      }
    }
  .custom-prev-arrow,
  .custom-next-arrow {
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .custom-prev-arrow {
    left: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  .custom-next-arrow {
    right: 0;
    transform: translateX(50%) translateY(-50%);
  }
}