@import "./base.scss";

section#header {
    position: relative;
    width: 100%;
    @include flexCenter;
    margin: 0;
    height: 450px;
    z-index: 5;   
    .st2{color:$orange-1;}
    >div#slogan {
        position: relative;
        width: 90%;
        margin: 20px;
        font-size: 1.4em;
        font-family: 'Bitter', serif;
        font-weight: 400;
        h1 {
            margin: 0;
            font-weight: 400;
            color: $orange-4;
        }
        h2 {
            margin: 0;
            font-weight: 400;
            color: $orange-2;
        }
    }
    #searchBar {
        position: relative;
        width: 80%;
        margin-right: auto; 
        margin-left: auto;
            input {
            font-size: 0.7em;
            width: 200px;
            margin-top: 15px;
            text-align: center;
            background-color: $orange-2;
            background-image: url("./svg/svgsearch.svg");
            background-position: 0px 0px; /* Position the search icon */
            background-repeat: no-repeat; /* Do not repeat the icon image */
            padding: 7px 10px 7px 20px; /* Add some padding */
        }
        #searchList {
            list-style: none;
            position: absolute;
            z-index: 10;
            padding: 0;
            background-color: red;
            width: 100%;
            margin-right: auto; 
            margin-left: auto;
            li {
                background-color: $orange-2;
                border: 1px solid $orange-4;
                border-top: none;
                margin-left: 0;
                padding: 5px;
                text-align: center;
                &:hover {
                    background-color: $orange-4;
                    color: $orange-2;
                }
            }
        }
    }
    .sBackground {
        top: 0;
        height: 100%;
        background-size: cover;
        background-image: url("./photos/Main_slider.jpg");
        z-index: -1;
    }
}
