/*
font-family: 'Bitter', serif;
font-family: 'Nunito Sans', sans-serif;
*/

@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,700;1,400&family=Nunito+Sans:wght@400;700&display=swap');

$blue-4: hsl(198, 93%, 16%);
$blue-35: #003D5C;
$blue-3: hsl(198, 93%, 22%);
$blue-2: hsl(200, 100%, 36%);
$blue-1: hsl(199, 99%, 44%);

$grey-6: rgb(80, 80, 80);
$grey-5: rgb(110, 110, 110);
$grey-4: rgb(140, 130, 140);
$grey-3: rgb(180, 180, 180);
$grey-2: rgb(200, 200, 200);
$grey-1: rgb(230, 230, 230);


$orange-5: hsl(23, 85%, 56%);
$orange-54: #E48B53;
$orange-4: hsl(23, 82%, 65%);
$orange-3: hsl(23, 70%, 83%);
$orange-2: hsl(24, 50%, 90%);
$orange-15: hsl(24, 50%, 95%);
$orange-1: hsl(21, 7%, 99%);

$colapse: 660px;

@mixin colapseRow {
    @media screen and (max-width: $colapse) {
      flex-direction: column !important;
  }
}

.colapseRow {
  @include colapseRow;
}

html {
    font-size: 15px;
    font-family: 'Nunito Sans', sans-serif;
    margin: 0;
    background-color: $orange-1;
    body {
        margin: 0;
        box-sizing: border-box;
        min-width: 460px;
    }
}

* {
  box-sizing: border-box;
}



a {
    text-decoration: none;
    color: inherit;
    &:active {
         color: $blue-2;
    }
}

section {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
}

header {
  margin: 0;
  padding: 0;
}

.sBackground {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.magicMargin {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.fadeIn {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;

}
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

@mixin flexCenter {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;

    align-items: center;
    justify-items: center;
  }

  