@import "./base.scss";

section#menu {
    position: absolute;
    width: 80%;
    min-width: 360px !important;
    margin-top: 14px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-items: center;
   // justify-content: space-between;
    text-transform: uppercase;
    color: $orange-1;
    font-weight: 400;
    z-index: 10;
    nav {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        .topRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            #langLink {
                display: flex;
                flex-direction: row;
                .lang {
                    @include flexCenter;
                    cursor: pointer;
                    font-size: 0.7em;
                    border: 1.5px $orange-1 solid;
                    width: 20px;
                    height: 20px;
                    padding: 1px;
                    margin: 5px;
                    &:hover, &.marked:hover {
                        background-color: $orange-5;
                    }
                    &.marked {
                        background-color: $orange-4;
                    }
                }
            }
            >div#rectaLogoHome {
                cursor: pointer;
                height: 100%;
                width: 100px;
                margin: 0;
                .st0{fill:$orange-1;}
                .st1{fill-rule:evenodd;clip-rule:evenodd;fill:$orange-1}
                &:hover {
                    .st0{fill:$orange-4;}
                    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:$orange-4}
                } 
            }
        }
        ul {
            margin: 0;
            padding: 0;
            width: 100%;
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: baseline;
            justify-content: space-between;
            justify-items: baseline;
            bottom: 0;
            font-size: 0.7em;
            border-top: 2px solid $orange-1;
            li {
                width: 100px;
              //  display: flex;
              //  align-self: center;
              //  justify-self: center;
                a {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    padding-top: 10px;
                    padding-bottom: 7px;
                    border-top: solid 5px transparent;
                    transform: translateY(-4px);
                    white-space: nowrap;
                    letter-spacing: 0.1em;
                    &:hover {
                        font-weight: 700;
                        border-top: solid 5px $orange-4;
                        color: $orange-4;
                    }
                }
            } 
        }
    }
}
