@import "./base.scss";

article#customAlert {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    display: flex;
    position: fixed;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background-color: rgba($color: white, $alpha: 0.4);
    backdrop-filter: blur(3px) ;
    >div.alertContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
        position: absolute;
        border-radius: 20px;
        background-color: white;
        border: 2px $blue-4 solid;
        z-index: 2;
        text-align: center;
        width: 50%;
        h6, h5 {
            color: $blue-4;
            font-weight: 400;
            margin: 0;
        }
        h5 {
            margin-top: 25px;
            font-size: 1em;
            font-weight: 700;
        }
        h6 {
            font-size: 0.8em;
        }
        p {
            color: $grey-6;
            margin: 15px;
            font-size: 0.7em;
        }
        button {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 25px;
            cursor: pointer;
            background-color: $orange-5;
            color: $orange-1;
            border: none;
            border-radius: 40px;
            padding: 5px 14px 5px 14px;
            &:hover {
                background-color: $orange-3;
                color: $blue-3;
            }
        }
    }
}